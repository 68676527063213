<div class="default-widget-modal">
  <ng-container *transloco="let t">
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
    </div>

    <div [ngSwitch]="uploadType" class="modal-body">
      <ng-container *ngSwitchCase="FileType.Image">
        <app-image-uploader
          *ngIf="isCropper && !(fileResult$ | async) || !isCropper"
          [storageFolder]="storageFolder"
          [extensions]="extensions"
          [maxFileUploadSize]="maxFileUploadSize"
          [allowedImageSize]="allowedImageSize"
          (uploadChanged)="onUploadChanged($event)"
          (bigpreview)="isCropper = false"
        ></app-image-uploader>

        <ng-container *ngIf="isCropper">
          <app-image-uploader-cropper
            *ngIf="fileResult$ | async as fileResult"
            [title]="title"
            [base64]="fileResult.fileDataUrl"
            [imageChangeEvent]="fileChangeEvent"
            [aspectRatio]="aspectRatio"
            (croppedChanged)="onCroppedChanged($event)"
          ></app-image-uploader-cropper>
        </ng-container>
      </ng-container>

      <app-file-uploader
        *ngSwitchCase="FileType.File"
        [fileType]="uploadType"
        [storageFolder]="storageFolder"
        [extensions]="extensions"
        [maxFileUploadSize]="maxFileUploadSize"
        (uploadChanged)="onUploadChanged($event)"
      ></app-file-uploader>

      <app-file-uploader
        *ngSwitchCase="FileType.Video"
        [fileType]="uploadType"
        [storageFolder]="storageFolder"
        [extensions]="extensions"
        [maxFileUploadSize]="maxFileUploadSize"
        (uploadChanged)="onUploadChanged($event)"
      ></app-file-uploader>
    </div>

    <div class="modal-footer p-2">
      <div class="card-body row g-3">
        <div class="col-md-6">
          <button type="button" class="btn btn-outline w-100" (click)="dismiss()">
            <i class="fa-lg mdi mdi-cancel"></i>
            {{ t("common.controls.cancel") }}
          </button>
        </div>
        <div class="col-md-6">
          <div class="btn-position">
            <button type="button" class="btn btn-primary w-100" [disabled]="disabled$ | async" (click)="confirm()">
              <i class="fa-lg mdi mdi-send"></i>
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
