export const maxImageUploadSize = 100; // 100MB
export const maxFileUploadSize = 20; // 20MB
export const maxVideoUploadSize = 50; // 50MB

// TODO: Check FileType on the backend and replace with string values
export enum FileType {
  Image = 1,
  File = 2,
  Video = 3
}

export enum FileMimeType {
  image = "image/*",
  png = "image/png",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls = "application/vnd.ms-excel",
  video = "video/*",
  mp4 = "video/mp4",
  webm = "video/webm",
  ogg = "video/ogg"
}

export interface ImageSize {
  width: number;
  height: number;
}

export interface VideoInfo {
  videoUrl: string;
  thumbnailUrl: string;
}
