import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import {
  CfsContentAsset,
  CfsContentSettingKeys,
  CfsQrCodeContentAsset,
  ContentSettings,
  DigitalAsset,
  KeyValueAsset,
  KeyValueDto,
  ScreenSizeType,
  TemplatePreviewOptions
} from "@app/models";
import { TemplateService } from "@app/services";
import { TranslocoService } from "@ngneat/transloco";
import { AnimationOptions } from "ngx-lottie";
import { forkJoin, Observable, of, ReplaySubject, Subject } from "rxjs";
import { catchError, takeUntil } from "rxjs/operators";
import { TemplateUtils } from "@app/template";
import { AnimationItem } from "lottie-web";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

type ContentAssetByKeys = {
  [key in CfsContentAsset]: string;
};

type ResponsiveAssetsInfo = {
  [screenSize in ScreenSizeType]?: DigitalAsset;
};

type AnimationDataSizeAsset = {
  [screenSize in ScreenSizeType]?: any;
};

@Component({
  selector: "app-template-preview",
  templateUrl: "./template-preview.component.html",
  styleUrls: ["./template-preview.component.scss"]
})
export class TemplatePreviewComponent implements AfterViewInit, OnDestroy {
  private readonly backgroundImageKey: string = "%%BACKGROUND_IMAGE%%";
  private contentAssetByKeys: ContentAssetByKeys = {
    [CfsContentAsset.MainBackgroundColor]: CfsContentSettingKeys.BackgroundColor,
    [CfsContentAsset.ButtonBackgroundColor]: CfsContentSettingKeys.ButtonColor,
    [CfsContentAsset.MainBackgroundColorHex]: "",
    [CfsContentAsset.ButtonBackgroundColorHex]: "",
    [CfsContentAsset.Param1]: "",
    [CfsContentAsset.Param2]: CfsContentSettingKeys.CampaignSchedule,
    [CfsContentAsset.Param3]: CfsContentSettingKeys.TemplateCategory,
    [CfsContentAsset.Param4]: "",
    [CfsContentAsset.Param5]: "",
    [CfsContentAsset.Param6]: ""
  };
  private colorSettingKeys = [CfsContentSettingKeys.BackgroundColor, CfsContentSettingKeys.ButtonColor];
  private backgroundColor: string;
  private backgroundImage: string;
  private qrCodeUrl: string;
  private imageUrl: string;
  private unsubscribe$ = new Subject<void>();
  private translationReady$ = new ReplaySubject<void>(1);

  templateOptions: TemplatePreviewOptions;
  animationDataSizeAsset: AnimationDataSizeAsset = {
    [ScreenSizeType.Full]: null,
    [ScreenSizeType.Compact]: null,
    [ScreenSizeType.Vertical]: null
  };
  templateScreenSize: ScreenSizeType;
  lottieOptions$ = new Subject<AnimationOptions>();
  htmlBody: SafeHtml = "";

  constructor(private templateService: TemplateService, private translateService: TranslocoService, private sanitizer: DomSanitizer) {
    this.translateService.selectTranslate("").subscribe(() => {
      // Translation load attempt finished, no meter succeed or not.
      this.translationReady$.next();
      this.translationReady$.complete();
    });
  }

  @Input() type: "standard" | "lottie" = "lottie"; // only lottie type realized for now
  @Input() fullScreenWrapper = false;
  @Input() isPrintView = false;

  @Input() set screenSize(screenSize: ScreenSizeType) {
    if (this.templateScreenSize && this.templateScreenSize === screenSize) return;

    this.templateScreenSize = screenSize ?? ScreenSizeType.Full;

    if (this.animationDataSizeAsset[screenSize || ScreenSizeType.Full]) {
      // set only if templateOptions is already set
      this.setAnimationToOptions();
    }
  }

  @Input() set options(options: TemplatePreviewOptions) {
    this.templateOptions = options;

    if (options?.digitalAsset || options?.mediaContent) {
      this.setLottieOptions(options);
    } else {
      this.htmlBody = options.htmlBody ? this.sanitizer.bypassSecurityTrustHtml(options.htmlBody) : "";
    }
  }

  @Output() printReady = new EventEmitter();

  @ViewChild("lottieContainer") lottieContainer: ElementRef;

  ngAfterViewInit(): void {
    this.setBackground();
  }

  animationCreated(animationItem: AnimationItem): void {
    if (this.isPrintView) {
      animationItem.goToAndStop(0);
    }
  }

  domLoaded(): void {
    if (this.isPrintView) {
      const waitingList = [];

      if (this.imageUrl) {
        waitingList.push(fetch(this.imageUrl));
      }

      if (this.qrCodeUrl) {
        waitingList.push(fetch(this.qrCodeUrl));
      }

      Promise.all(waitingList).then(() => {
        this.printReady.emit();
      });
    }
  }

  private setBackground(): void {
    if (this.lottieContainer) {
      if (this.backgroundColor) {
        this.lottieContainer.nativeElement.style.backgroundColor = this.backgroundColor;
        this.lottieContainer.nativeElement.style.backgroundImage = "";
      } else if (this.backgroundImage) {
        this.lottieContainer.nativeElement.style.backgroundImage = this.backgroundImage;
        this.lottieContainer.nativeElement.style.backgroundColor = "";
      }
      if (this.isPrintView) {
        this.lottieContainer.nativeElement.style.backgroundColor = "#fff";
        this.lottieContainer.nativeElement.style.backgroundImage = "";
      }
    }
  }

  private setLottieOptions(options: TemplatePreviewOptions): void {
    const { contentSettings, defaultParameters } = options;
    this.backgroundColor = contentSettings?.backgroundColor || TemplateUtils.parseQrCodeBackgroundColor(defaultParameters);
    this.backgroundImage = this.getBackgroundImage(options);

    const assetsInfo: ResponsiveAssetsInfo = this.getResponsiveAssetsInfo(options);
    const contentUrlList$: Observable<string>[] = Object.values(assetsInfo).map((asset: DigitalAsset) =>
      this.getContentByUrl(asset?.contentUrl)
    );

    this.setBackground();

    forkJoin(contentUrlList$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((jsonList: [string, string, string]) => {
        this.translationReady$.subscribe(() => {
          this.animationDataSizeAsset = Object.keys(assetsInfo).reduce((acc: ResponsiveAssetsInfo, itemScreenSize, index: number) => {
            const assetItem: DigitalAsset = assetsInfo[itemScreenSize];
            const itemDefaultParams: KeyValueDto[] = assetItem?.configuration.defaultParameters;
            const itemJson = jsonList[index];

            return {
              ...acc,
              [itemScreenSize]: this.getAnimationData(itemJson, itemDefaultParams, options)
            };
          }, {} as any);

          this.setAnimationToOptions();
        });
      });
  }

  private getContentByUrl(url: string): Observable<string> {
    if (!url) return of("");

    return this.templateService.getContentByUrl(url).pipe(catchError(() => of("")));
  }

  private getBackgroundImage(options: TemplatePreviewOptions): string {
    const params: KeyValueAsset = this.getAllKeyValueAssetParams(options, []);

    return Object.keys(params).reduce((acc: string, key: string) => {
      const value = params[key];

      if (key === this.backgroundImageKey) {
        acc = value;
      }

      return acc;
    }, "");
  }

  private setAnimationToOptions(): void {
    const animationData = this.animationDataSizeAsset[this.templateScreenSize || ScreenSizeType.Full];
    const lottieOptions: AnimationOptions = { loop: true, autoplay: true, animationData };

    this.lottieOptions$.next(lottieOptions);
  }

  private getAnimationData(jsonText: string, digitalDefaultParams: KeyValueDto[], options: TemplatePreviewOptions): any {
    if (!jsonText) return null;

    const { contentSettings, mediaContent } = options;
    const isOldLottie = contentSettings?.displayContent || contentSettings?.templateCategory;

    if (mediaContent && !isOldLottie) {
      const allParams: KeyValueAsset = this.getAllKeyValueAssetParams(options, digitalDefaultParams);

      if (allParams[CfsQrCodeContentAsset.qrCodeUrl]) {
        this.qrCodeUrl = allParams[CfsQrCodeContentAsset.qrCodeUrl];
      }

      if (allParams[CfsQrCodeContentAsset.imageUrl]) {
        this.imageUrl = allParams[CfsQrCodeContentAsset.imageUrl];
      }

      Object.keys(allParams).forEach((key: string) => {
        jsonText = jsonText.replace(key, allParams[key]);
      });

      return JSON.parse(jsonText);
    } else {
      const parameters: KeyValueDto[] = digitalDefaultParams;
      return contentSettings
        ? this.getLottieJson(jsonText, parameters, contentSettings)
        : this.getLottieJsonByAssetParams(jsonText, parameters);
    }
  }

  private getAllKeyValueAssetParams(options?: TemplatePreviewOptions, digitalDefaultParams: KeyValueDto[] = []): KeyValueAsset {
    const { defaultParameters, mediaContent } = options || {};

    const assetParameters: KeyValueAsset = TemplateUtils.getKeyValueAsset(digitalDefaultParams);
    const mediaParams: KeyValueAsset = TemplateUtils.getKeyValueAsset(mediaContent?.configuration.defaultParameters);

    return { ...assetParameters, ...mediaParams, ...defaultParameters };
  }

  private getResponsiveAssetsInfo(options: TemplatePreviewOptions): ResponsiveAssetsInfo {
    const { digitalAsset, mediaContent } = options;

    if (mediaContent) {
      const digitalAssets = mediaContent.digitalAssets;

      return Object.values(ScreenSizeType).reduce((acc: ResponsiveAssetsInfo, size: string) => {
        const itemDigitalAsset: DigitalAsset = digitalAssets.find((item: DigitalAsset) => {
          const itemScreenSize = item.configuration.screenSize;
          return itemScreenSize === size || (!itemScreenSize && size === ScreenSizeType.Full);
        });

        return {
          ...acc,
          [size]: itemDigitalAsset
        };
      }, {} as ResponsiveAssetsInfo);
    } else {
      return { [ScreenSizeType.Full]: digitalAsset, [ScreenSizeType.Compact]: null, [ScreenSizeType.Vertical]: null };
    }
  }

  private getLottieJsonByAssetParams(jsonText: string, params: KeyValueDto[]): any {
    params.forEach((item: KeyValueDto) => {
      jsonText = jsonText.replace(item.key, item.value);
    });

    return JSON.parse(jsonText);
  }

  private getLottieJson(text: string, params: KeyValueDto[], settings: ContentSettings): any {
    Object.keys(this.contentAssetByKeys).forEach((key: string) => {
      let value: string = this.tryGetPlaceholder(key, settings);

      if (!value) {
        if (key === CfsContentAsset.Param1) {
          let displayContent: string = TemplateUtils.getValueWithFirstUpperLetter(settings.displayContent);

          if (!displayContent) displayContent = TemplateUtils.getValueWithFirstUpperLetter(settings.templateCategory);

          value = displayContent;
        } else {
          value = this.getJsonValueDependOnKey(key, params, settings);
        }
      }

      text = text.replace(key, value.replace(/"/g, '\\"'));
    });

    return JSON.parse(text);
  }
  private tryGetPlaceholder(key: string, settings: ContentSettings) {
    if (key === CfsContentAsset.Param1 && !settings.displayContent)
      return this.translateService.translateObject("templates.templateTopPlaceholder" + (settings.isPredefined ? "Select" : "Enter"));
    if (key === CfsContentAsset.Param3 && !settings.templateCategory)
      return this.translateService.translateObject("templates.templateButtonPlaceholder");
    if (key === CfsContentAsset.Param2 && !settings.campaignSchedule)
      return this.translateService.translateObject("templates.campaignSchedule" + (settings.isPredefined ? "Select" : "Enter"));
    return null;
  }

  private getJsonValueDependOnKey(key: string, params: KeyValueDto[], settings: ContentSettings): string {
    let value: string;
    const contentSettingKey = this.contentAssetByKeys[key];
    const valueBySettings: string = contentSettingKey ? settings[contentSettingKey] : null;
    value = valueBySettings ? valueBySettings : this.getContentDefaultValue(params, key);

    if (valueBySettings && contentSettingKey === CfsContentSettingKeys.CampaignSchedule) {
      value = value.toUpperCase();
    } else if (valueBySettings && this.colorSettingKeys.some((colorKey: string) => colorKey === contentSettingKey)) {
      value = TemplateUtils.getLottieColorByHex(value);
    } else {
      value = TemplateUtils.getValueWithFirstUpperLetter(value);
    }

    return value;
  }

  private getContentDefaultValue(assetParams: KeyValueDto[], key: string): string {
    return assetParams.find((item: KeyValueDto) => item.key === key)?.value;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
