import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../core";
import {
  CustomerDistributionAccountGetDto,
  CustomerDistributionAccountPostDto,
  CustomerDistributionAccountsPostDto,
  CustomerDistributionDetailsGetDto,
  CustomerDistributionGetDto,
  CustomerDistributionPostDto,
  CustomerDistributionPutDto,
  CustomerDistributionSentMessageResponseDto
} from "../dto";

@Injectable({
  providedIn: "root"
})
export class CustomerDistributionService {
  private serviceUrl = "CustomerDistribution";

  constructor(private api: ApiService) {}

  getCustomerDistributionAccountList(): Observable<CustomerDistributionDetailsGetDto[]> {
    return this.api.get(`${this.serviceUrl}/account`);
  }

  getCustomerDistributionPartnerList(): Observable<CustomerDistributionDetailsGetDto[]> {
    return this.api.get(`${this.serviceUrl}/partner`);
  }

  getCustomerDistribution(id: number): Observable<CustomerDistributionGetDto> {
    return this.api.get(`${this.serviceUrl}/${id}`);
  }

  updateCustomerDistribution(id: number, data: CustomerDistributionPutDto): Observable<CustomerDistributionGetDto> {
    return this.api.put(`${this.serviceUrl}/${id}`, data);
  }

  delete(id: number): Observable<void> {
    return this.api.delete(`${this.serviceUrl}/${id}`);
  }

  createCustomerDistribution(data: CustomerDistributionPostDto): Observable<CustomerDistributionGetDto> {
    return this.api.post(`${this.serviceUrl}`, data);
  }

  getCustomerDistributionAccounts(distributionId: number): Observable<CustomerDistributionGetDto> {
    return this.api.get(`${this.serviceUrl}/${distributionId}/accounts`);
  }

  createCustomerDistributionAccounts(
    distributionId: number,
    data: CustomerDistributionAccountsPostDto
  ): Observable<CustomerDistributionAccountGetDto> {
    return this.api.post(`${this.serviceUrl}/${distributionId}/accounts`, data);
  }

  updateCustomerDistributionAccounts(
    distributionId: number,
    data: CustomerDistributionAccountsPostDto
  ): Observable<CustomerDistributionAccountPostDto[]> {
    return this.api.put(`${this.serviceUrl}/${distributionId}/accounts`, data);
  }

  sendCustomerDistribution(distributionId: number): Observable<CustomerDistributionSentMessageResponseDto> {
    return this.api.post(`${this.serviceUrl}/${distributionId}/accounts`);
  }
}
