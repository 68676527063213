<app-base-uploader
  [size]="size"
  [label]="label"
  [uploadLabel]="'common.file.uploadImage' | transloco"
  [isDisabled]="isDisabled"
  [isRequired]="isRequired"
  [isClearable]="isClearable"
  [value]="value"
  (clear)="onClear()"
  (upload)="onUpload()">
  <img
    *ngIf="value"
    class="uploader-media"
    alt=""
    [src]="value"
    data-holder-rendered="true"
  />
</app-base-uploader>
