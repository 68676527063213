import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { FileMimeType, FileResult, FileType, FileUploaderModalComponent, maxVideoUploadSize, VideoInfo } from "@app/shared/file-uploader";
import { BaseUploaderComponent } from "../base-uploader";

@Component({
  selector: "app-custom-video-uploader",
  templateUrl: "./custom-video-uploader.component.html",
  styleUrls: ["./custom-video-uploader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomVideoUploaderComponent,
      multi: true
    }
  ]
})
export class CustomVideoUploaderComponent extends BaseUploaderComponent<VideoInfo> {
  @Input() storageFolder = "files";
  @Input() extensions: Array<FileMimeType> = [FileMimeType.mp4, FileMimeType.webm, FileMimeType.ogg];
  @Input() maxFileUploadSize = maxVideoUploadSize;

  value: VideoInfo;

  constructor(protected changeDetectorRef: ChangeDetectorRef, private modalService: NgbModal, private transloco: TranslocoService) {
    super(changeDetectorRef);
  }

  onUpload(): void {
    const imageModal = this.modalService.open(FileUploaderModalComponent, { backdrop: "static", size: "lg", centered: true });
    const comp: FileUploaderModalComponent = imageModal.componentInstance;
    comp.uploadType = FileType.Video;
    comp.storageFolder = this.storageFolder;
    comp.maxFileUploadSize = this.maxFileUploadSize;
    comp.title = this.transloco.translate("common.file.uploadVideo");
    comp.buttonText = this.transloco.translate("common.file.saveVideo");
    comp.extensions = this.extensions;

    imageModal.result
      .then((result: FileResult) => {
        if (result) {
          const videoInfo: VideoInfo = { videoUrl: result.fileUrl, thumbnailUrl: result.thumbnailUrl };

          this.updateValue(videoInfo);
        }
      })
      .catch(() => {});
  }

  onClear(): void {
    this.updateValue(null);
  }
}
