import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { Account, Location } from "@app/models";
import { TranslocoModule } from "@ngneat/transloco";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { cloneFlatArray, distinctUntilPlainObjectChanged, UiKitModule } from "ui-kit";
import { LocationAssignmentModalComponent, LocationAssignmentModalOptions } from "../";
import { Unsubscriber } from "../base/unsubscriber.component";
import { SelectMultipleComponent } from "../select/select-multiple/select-multiple.component";

export interface AccountLocationAssignerResource {
  accounts: Account[];
  locations: Location[];
  assignedItems: AccountLocationAssignedItem[];
  isAccountAdminRole?: boolean;
}

export interface AccountLocationAssignedItem {
  companyName: string;
  accountId: number;
  locationIds: number[];
  useForAllLocations: boolean;
}

export interface AccountItem {
  companyName: string;
  accountId: number;
}

@Component({
  selector: "app-account-location-assigner",
  templateUrl: "./account-location-assigner.component.html",
  styleUrls: ["./account-location-assigner.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatDialogModule,
    SelectMultipleComponent,
    UiKitModule,
    LocationAssignmentModalComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountLocationAssignerComponent),
      multi: true
    }
  ]
})
export class AccountLocationAssignerComponent extends Unsubscriber implements OnInit {
  @Input() resources: AccountLocationAssignerResource = null;

  protected form = new FormGroup({
    accountIds: new FormControl<number[]>(null, [Validators.required]),
    locationIds: new FormControl<number[]>(null, [Validators.required])
  });

  protected useForAllAccounts = false;
  protected accounts: AccountItem[] = [];
  /** The list of items to reflect Accounts with assigned Locations to it */
  protected assignedItems$ = new BehaviorSubject<AccountLocationAssignedItem[]>([]);

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.sub = this.form.controls.accountIds.valueChanges
      .pipe(
        distinctUntilPlainObjectChanged,
        filter(() => !!this.resources?.accounts?.length)
      )
      .subscribe((accountIds) => {
        console.log("accountIds.valueChanges:", accountIds); // fixme
        const selectedAccounts = this.resources.accounts.filter((account) => accountIds.includes(account.id));
        const assignedItems = selectedAccounts.map((account: Account) => {
          const { id, companyName } = account;
          const existedAssignment = this.resources.assignedItems.find((x) => x.accountId === id);

          return {
            accountId: id,
            companyName,
            locationIds: existedAssignment?.locationIds || [],
            useForAllLocations: existedAssignment?.useForAllLocations ?? true
          };
        });

        this.updateAssignedItems(assignedItems);
      });

    this.sub = this.form.valueChanges.pipe(distinctUntilPlainObjectChanged).subscribe(() => {
      this.onTouched();
      this.onModelChange();
    });
  }

  writeValue(value: AccountLocationAssignedItem[]): void {
    if (value.length) {
      this.form.setValue(
        {
          accountIds: value.map((item) => item.accountId),
          locationIds: value.reduce((acc, item) => [...acc, ...item.locationIds], [])
        },
        { emitEvent: false }
      );
    } else {
      this.form.reset();
    }

    this.updateAssignedItems(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  openLocationModal(selectedAccountId: number): void {
    const assignedItems = cloneFlatArray(this.assignedItems$.value);
    const data: LocationAssignmentModalOptions = {
      assignedItems,
      locations: this.resources?.locations || [],
      selectedAccountId: selectedAccountId || this.assignedItems$.value[0].accountId
    };

    this.dialog
      .open(LocationAssignmentModalComponent, {
        data,
        panelClass: "modal-standard-s",
        autoFocus: false,
        disableClose: true
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((items: AccountLocationAssignedItem[]) => {
        this.updateAssignedItems(items);
        this.form.patchValue(
          {
            accountIds: items.map((item) => item.accountId),
            locationIds: items.reduce((acc, item) => [...acc, ...item.locationIds], [])
          },
          { emitEvent: true }
        );
      });
  }

  private onModelChange(): void {
    this.onChange(this.assignedItems$.value);
  }

  private updateAssignedItems(value: AccountLocationAssignedItem[]): void {
    const items = cloneFlatArray(value);

    this.assignedItems$.next(items);
    console.log("assignedItems$.next:", items); // fixme
  }
}
