import { NewRegistrationStatus } from "./new-registration-status";
import { NewRegistrationType } from "./new-registration-type";

export class NewRegistrationModel {
  id: number;
  status: NewRegistrationStatus;
  registrationType: NewRegistrationType;
  subscriptionPlanId: number;
  subscriptionPlanName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  state: string;
  address: string;
  city: string;
  zip: string;
  country: number;
  companyName: string;
  website?: string;
  sendToEmail?: string;
  linkUrl?: string;
  createdOn: string;
  partnerId?: number;
  partnerName?: string;
  locationQuantity?: number;
}
