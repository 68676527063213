
<div class="modal-container" *transloco="let t; read: 'templates'">
  <div class="btn-close btn-container pe-4" (click)="onClose()"></div>

  <form [formGroup]="form">
    <lib-dropdown
      [control]="form.controls.selectedAccountId"
      [isRequired]="false"
      [closeOnSelect]="true"
      [searchable]="false"
      [multiple]="false"
      [bindLabel]="'companyName'"
      [bindValue]="'accountId'"
      [label]="t('accounts')"
      [items]="assignedItems"
    >
    </lib-dropdown>

    <app-checkbox
      [control]="form.controls.useForAllLocations"
      [inlineLabel]="t('useForAllLocations')"
      [tooltip]="t('useForAllLocationsTooltip')"
      [tooltipTriggers]="'hover'"
      tooltipSize="wide"
      (selected)="onUseForAllLocations($event)"
    ></app-checkbox>

    <app-location-autocomplete-select
      class="d-flex w-100"
      [selectedLocationIds]="selectedLocationIds"
      [locations]="accountLocationList$ | async"
      [disabled]="isUsedForAllLocations"
      [selectionType]="'multiple'"
      (selectedLocationsChanged)="onSelectedLocationsChanged($event)"
    >
    </app-location-autocomplete-select>
  </form>
</div>

<div class="modal-footer border-top">
  <button
    class="btn btn-outline-light btn-card-action float-start"
    (click)="onReset()">
    {{ "common.controls.reset" | transloco }}
  </button>

  <button
    class="btn btn-primary btn-card-action float-end"
    (click)="onSave()">
    {{ "common.controls.save" | transloco }}
  </button>
</div>
