<app-base-uploader
  [size]="size"
  [label]="label"
  [uploadLabel]="'common.file.uploadVideo' | transloco"
  [isDisabled]="isDisabled"
  [isRequired]="isRequired"
  [isClearable]="isClearable"
  [value]="value"
  (clear)="onClear()"
  (upload)="onUpload()">
  <video
    *ngIf="value?.videoUrl"
    class="uploader-media"
    [src]="value.videoUrl"
    [poster]="value.thumbnailUrl"
    autoplay
    loop
    muted>
  </video>
</app-base-uploader>
