<div
  [ngClass]="
    {
      'row': !isDisabled && !(size === 'L' || size === 'XL'),
      'col-xxl-8 col-xl-8 col-lg-10 col-md-10 col-sm-7 col-7': size !== 'L',
      'col-md-12': size === 'L'
    }"
  class="w-100">
  <div class="form-group">
    <div [class.col-md-6]="!isDisabled && size === 'M'">
      <div class="image-logo-container">
        <label *ngIf="label" class="col-form-label {{ labelCssClass }}">
          <span>{{ label }}</span>
          <span *ngIf="isRequired" class="text-danger">*</span>
          <i
            *ngIf="tooltip"
            [ngbTooltip]="tooltip"
            class="bx bx-question-mark text-info font-size-18">
          </i>
        </label>

        <div class="position-relative image-logo-content-{{size}}">
          <i
            *ngIf="!isDisabled && isClearable && value"
            class="mdi mdi-close-circle clear-button text-danger"
            [ngbTooltip]="'common.controls.delete' | transloco"
            (click)="onClear($event)">
          </i>

          <div
            class="image-logo-content image-logo-content-{{size}}"
            [class.uploader]="!isDisabled"
            (click)="onUpload()">
            <div class="d-flex justify-content-center image-logo image-logo-{{size}}">
              <ng-content></ng-content>
            </div>

            <div
              *ngIf="!value"
              class="upload-message-container upload-message-placeholder upload-message-container-{{ size }}">
              <span class="fas fa-plus upload-plus"></span>
              <span class="upload-text">{{ 'common.file.upload' | transloco }}</span>
            </div>

            <div *ngIf="!isDisabled" class="upload-message-container upload-message upload-message-{{ size }}">
              {{ uploadLabel | transloco }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
